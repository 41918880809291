<template>
    <b-container class="survey__container">
        <stepper
            :steps="baseSteps"
            @completed-step="completeStep"
            @active-step="isStepActive"
            @stepper-finished="finishedSurvey"
        ></stepper>
    </b-container>
</template>

<script>
import Stepper from "vue-stepper";
import StepOne from "./steps/StepOne.vue";
import StepTwo from "./steps/StepTwo.vue";
import StepThree from "./steps/StepThree.vue";
import StepFour from "./steps/StepFour.vue";
export default {
    components: {
        Stepper
    },
    data() {
        return {
            baseSteps: [
                {
                    icon: "check",
                    name: "first",
                    title: "Frage 1",
                    component: StepOne,
                    completed: false
                },
                {
                    icon: "check",
                    name: "second",
                    title: "Frage 2",
                    component: StepTwo,
                    completed: false
                },
                {
                    icon: "check",
                    name: "third",
                    title: "Frage 3",
                    component: StepThree,
                    completed: false
                },
                {
                    icon: "check",
                    name: "four",
                    title: "Frage 4",
                    component: StepFour,
                    completed: false
                }
            ]
        };
    },
    methods: {
        // Executed when @completed-step event is triggered
        completeStep(payload) {
            this.baseSteps.forEach(step => {
                if (step.name === payload.name) {
                    step.completed = true;
                }
            });
        },
        // Executed when @active-step event is triggered
        isStepActive(payload) {
            this.baseSteps.forEach(step => {
                if (step.name === payload.name) {
                    if (step.completed === true) {
                        step.completed = false;
                    }
                }
            });
        },
        // Executed when @stepper-finished event is triggered
        finishedSurvey() {
            this.$router.push("/finishedSurvey");
        }
    },
    mounted() {
        let next = document.querySelector(".stepper-button.next");
        let nextButton = next.getElementsByTagName("span")[0];
        nextButton.innerText = "Weiter";
    }
};
</script>

<style scoped>
.survey__container {
    margin-top: 40px;
}
</style>
