let questions = [
  {
    question: "Was zahlen Sie für Ihre KFZ-Versicherung jährlich?",
    answers: [
      {
        value: "1",
        name: "price",
        title: "300€ bis 500 €",
      },
      {
        value: "2",
        name: "price",
        title: "500€ bis 700€",
      },
      {
        value: "3",
        name: "price",
        title: "700€ bis 1200€",
      },
    ],
  },
  {
    question: "Welche KFZ-Versicherung bevorzugen Sie eher?",
    answers: [
      {
        value: "1",
        name: "legalage",
        title: "Haftpflicht",
      },
      {
        value: "2",
        name: "legalage",
        title: "Teilkasko",
      },
      {
        value: "3",
        name: "legalage",
        title: "Vollkasko",
      },
    ],
  },
  {
    question: "Sie sind mit Ihren aktuellen Anbieter zufrieden?",
    answers: [
      {
        value: "1",
        name: "provider",
        title: "Ja, bin ich.",
      },
      {
        value: "2",
        name: "provider",
        title: "Nein, absolut nicht.",
      },
      {
        value: "3",
        name: "provider",
        title: "Könnte besser sein.",
      },
    ],
  },
  {
    question: "Wie lange sind Sie bei Ihrer KFZ-Versicherung?",
    answers: [
      {
        value: "1",
        name: "save",
        title: "1 bis 3 Jahre",
      },
      {
        value: "2",
        name: "save",
        title: "3 bis 5 Jahre",
      },
      {
        value: "3",
        name: "save",
        title: "länger als 5 Jahre",
      },
    ],
  },
];

module.exports = questions;
