<template>
    <b-container class="question__container">
        <b-form-group
            :label="questions[3].question"
            v-slot="{ ariaDescribedby }"
        >
            <b-form-radio
                v-for="(item, i) of questions[3].answers"
                :key="i"
                v-model="form.selected"
                :aria-describedby="ariaDescribedby"
                :name="item.name"
                :value="item.value"
                >{{ item.title }}</b-form-radio
            >
        </b-form-group>
    </b-container>
</template>

<script>
import questions from "../../config/questions";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
    props: ["clickedNext", "currentStep"],
    mixins: [validationMixin],
    data() {
        return {
            form: {
                selected: ""
            },
            questions
        };
    },
    validations: {
        form: {
            selected: {
                required
            }
        }
    },
    watch: {
        $v: {
            handler: function(val) {
                if (!val.$invalid) {
                    this.$emit("can-continue", { value: true });
                } else {
                    this.$emit("can-continue", { value: false });
                }
            },
            deep: true
        },
        clickedNext(val) {
            if (val === true) {
                this.$v.form.$touch();
            }
        }
    },
    mounted() {
        if (!this.$v.$invalid) {
            this.$emit("can-continue", { value: true });
        } else {
            this.$emit("can-continue", { value: false });
        }
    }
};
</script>

<style scoped>
/* only bigger devices */
@media (min-width: 992px) {
    .question__container {
        width: 600px;
    }
}
</style>
